import * as XLSX from "xlsx";
const readExcel= function(files) {
  function uploadFile() {
    return new Promise(function(resolve, reject) {
        try {
          console.log(files);
          if (files.length <= 0) {
            return false;
          } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
            console.log("上传格式不正确，请上传xls或者xlsx格式");
            return false;
          }
          // 读取表格
          const fileReader = new FileReader();
          fileReader.onload = (ev) => {
            const workbook = XLSX.read(ev.target.result, {
              type: "binary",
            });
            const wsname = workbook.SheetNames[0];
            const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
            // console.log(ws); // 转换成json的数据
            //  const m = dealExcel(ws) // ...对数据进行自己需要的操作
             resolve(ws)
          };
          fileReader.readAsBinaryString(files);
        } catch (e) {
          resolve([])
        }
    })
  }
  return uploadFile().then(function(result){
    return result;
  })
}

export {
  readExcel
}